

































































































import './scss/BaseSelect.scss';
import './scss/BaseSelectAdaptive.scss';
import { Component, Prop, Vue } from 'vue-property-decorator';
import debounce from '../../../Modules/debounce.js';

@Component({
    name: 'BaseSelect',

    components: {
        BaseSelectOption: () => import('./BaseSelectOption.vue'),
    },
})
export default class BaseSelect extends Vue {
    @Prop({
        default: () => ([]),
        validator(value) {
            if (value.some((option: any) => typeof option === 'object' && option)) {
                const passed = value.every((option: any) => 'label' in option || 'title' in option);
                if (!passed) throw new Error('Validation error in BaseSelect: all options should contain "label" or "title" key');

                return passed;
            }
            return true;
        },
    })
    options!: any[];
    @Prop({ default: () => ({}) }) value!: any | any[];
    @Prop({ default: false }) multiselectable!: boolean;
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: false }) error!: boolean;
    @Prop({ default: false }) showChosenOption!: boolean;
    @Prop({ default: '' }) alignment!: 'left' | 'right';
    @Prop({ default: false }) borderNone!: boolean;
    @Prop({ default: false }) disabled!: boolean;
    @Prop({ default: false }) disabledList!: boolean;
    @Prop({}) label!: string;
    @Prop({}) placeholder!: string;
    @Prop({}) caption!: string;
    @Prop({}) disabledOptions!: string[];
    @Prop({}) disableProperty!: string;
    @Prop({}) disableReason!: string;
    @Prop({ default: false}) notShowSelectedOnMultiselect!: boolean;
    @Prop({ default: '' }) usingType!: '' | 'filter';

    emitInput(selected: any | any[]) {
        this.$emit('input', selected);
    }

    emitOptionSelected(option: any) {
        this.$emit('option-selected', option);
    }

    optionsOpen: boolean = false;

    get togglerPlaceholder() {
        return this.valueAsPlaceholderAvailable ? this.getChosenOptionPlaceholder() : this.placeholder;
    }

    get valueAsPlaceholderAvailable() {
        return this.showChosenOption && !this.multiselectable && (!!this.value?.label || !!this.value?.title);
    }

    get chosenOptionForMultiselectable() {
        const output: string[] = [];
        if (Array.isArray(this.value) && this.value.length) {
            this.value.forEach((item) => {
                output.push(item.title || item.label || item.value);
            })
        }
        return output.join(', ');
    }

    get optionsWithoutEmptyString(): any[] {
        return this.options.filter((option) => {
            if (typeof option === 'object') {
                return (option.title && option.title.trim() !== '')
                    || (option.label && option.label.trim() !== '');
            }
            return true;
        })
    }

    get optionsWithoutDuplicate(): any[] {
        const setWithoutDuplicate: Set<string> = new Set();
        this.optionsWithoutEmptyString.forEach((option) => {
            if (typeof option === 'object') {
                 if (option?.label) {
                     setWithoutDuplicate.add(option.label);
                 } else if (option?.title) {
                     setWithoutDuplicate.add(option.title);
                 }
            }
        });
        return Array.from(setWithoutDuplicate).map((key: string) => ({ label: key }));
    }

    get optionsList(): any[] {
        return this.usingType === 'filter' ? this.optionsWithoutDuplicate : this.optionsWithoutEmptyString;
    }

    get isEmptyOptions(): boolean {
        return this.optionsWithoutEmptyString.length === 0;
    }

    isOptionDisable(option) {
        if (!this.disabledOptions) return false
        return this.disableProperty
            ? this.disabledOptions.includes(option[this.disableProperty])
            : this.disabledOptions.includes(option);
    }

    getChosenOptionPlaceholder() {
        if (typeof this.value === 'object' && !Array.isArray(this.value)) {
            return this.value?.label || this.value?.title;
        }
        return this.value;
    }

    toggleOptionsOpen() {
        this.optionsOpen = !this.optionsOpen;
        setTimeout(() => {
            if (this.optionsOpen) {
                document.addEventListener('click', this.documentClickHandler);
            }
        }, 0);
    }

    documentClickHandler() {
        this.optionsOpen = false;
        document.removeEventListener('click', this.documentClickHandler);
    }

    closeOptions() {
        this.optionsOpen = false;
        document.removeEventListener('click', this.documentClickHandler);
    }

    isOptionSelected(option: any): boolean {
        return this.multiselectable
            ? this.checkOptionSelectedForMultiselect(option)
            : this.value === option;
    }

    checkOptionSelectedForMultiselect(option: any): boolean {

        let optionSelected: boolean = false;

        if (option && option?.label) {
            optionSelected = this.value.map((item) => item.label).includes(option.label);
        } else if (option && option?.title) {
            optionSelected = this.value.map((item) => item.title).includes(option.title);
        }
        return optionSelected;
    }

    changeSelected(option: any) {
        if (this.multiselectable) this.selectArrayOfOptions(option);
        else this.selectSingleOption(option);
        this.emitOptionSelected(option);
    }

    selectSingleOption(option: any) {
        this.emitInput(option);
        this.closeOptions();
    }

    defaultSelectArrayOfOptions(option: any): any[] {
        const arrayOfSelectedOptions: any[] = [...this.value];
        const optionIndex = arrayOfSelectedOptions.indexOf(option);

        if (optionIndex !== -1) arrayOfSelectedOptions.splice(optionIndex, 1);
        else arrayOfSelectedOptions.push(option);

        return arrayOfSelectedOptions;
    }

    filterSelectArrayOfOptions(option: any): any[] {
        let output: string[] = [];
        const arrayOfSelectedOptions: any[] = [...this.value];
        let optionIndex!: number;

        if (option?.label) {
            output = [...this.value.map(item => item.label)];
            optionIndex = output.indexOf(option.label);
        } else if (option?.title) {
            output = [...this.value.map(item => item.title)];
            optionIndex = output.indexOf(option.title);
        }

        if (optionIndex !== -1) arrayOfSelectedOptions.splice(optionIndex, 1);
        else arrayOfSelectedOptions.push(option);

        return arrayOfSelectedOptions;
    }

    selectArrayOfOptions(option: any): void {
        let output: any[] = [];
        switch(this.usingType) {
            case('filter'): {
                output = this.filterSelectArrayOfOptions(option);
                break;
            }
            default: {
                output = this.defaultSelectArrayOfOptions(option);
            }
        }

        this.emitInput(output);
    }
}
