var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-select"},[(_vm.label)?_c('p',{staticClass:"base-select__label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"base-select__selection-block"},[_c('button',{class:[
                'base-select__toggler',
                { 'base-select__toggler--open': _vm.optionsOpen },
                { 'base-select__toggler--border-none': _vm.borderNone },
                { 'base-select__toggler--error': _vm.error } ],attrs:{"disabled":_vm.disabled || _vm.isEmptyOptions,"title":_vm.disabled && _vm.disableReason ? _vm.disableReason : ''},on:{"click":_vm.toggleOptionsOpen}},[_c('span',{staticClass:"base-select__toggler-text"},[(_vm.multiselectable && !_vm.notShowSelectedOnMultiselect && _vm.chosenOptionForMultiselectable.length)?_c('span',{staticClass:"base-select__toggler-text-chosen"},[_vm._v(" "+_vm._s(_vm.chosenOptionForMultiselectable)+" ")]):_vm._e(),(_vm.multiselectable && !_vm.notShowSelectedOnMultiselect && _vm.chosenOptionForMultiselectable.length)?_c('span',{staticClass:"base-select__toggler-text-title"},[_vm._v(" ("+_vm._s(_vm.togglerPlaceholder)+") ")]):_vm._e(),(!_vm.multiselectable || (_vm.multiselectable && !_vm.chosenOptionForMultiselectable.length) || (_vm.multiselectable && _vm.notShowSelectedOnMultiselect))?_c('span',[_vm._v(" "+_vm._s(_vm.togglerPlaceholder)+" ")]):_vm._e()]),(!_vm.disabled)?_c('svg',{staticClass:"base-select__toggler-icon",attrs:{"width":"10","height":"6"}},[_c('use',{attrs:{"xlink:href":"#icon-arrow"}})]):_vm._e()]),(_vm.optionsOpen && !_vm.disabled)?_c('ul',{class:[
                'base-select__options',
                { 'base-select__options--open': _vm.optionsOpen },
                { 'base-select__options--left': _vm.alignment === 'left' },
                { 'base-select__options--right': _vm.alignment === 'right' } ],on:{"click":function($event){$event.stopPropagation();}}},[_c('li',{staticClass:"base-select__option"},_vm._l((_vm.optionsList),function(option,index){return _c('BaseSelectOption',{key:("option" + index),attrs:{"multiselectable":_vm.multiselectable,"selected":_vm.isOptionSelected(option),"option":option,"is-option-disabled":_vm.isOptionDisable(option),"disabled":_vm.disabledList},on:{"option-select":function($event){return _vm.changeSelected($event)}}})}),1)]):_vm._e()]),(_vm.required || _vm.caption)?_c('div',{staticClass:"base-select__caption"},[_c('svg',{staticClass:"base-select__caption-icon",attrs:{"width":"12","height":"12"}},[_c('use',{attrs:{"xlink:href":"#icon-alert"}})]),(_vm.required)?_c('p',{staticClass:"base-select__caption-require-alert"},[_vm._v(" Обязательно для заполнения ")]):_vm._e(),(_vm.caption)?_c('p',{staticClass:"base-select__caption-text"},[_vm._v(" "+_vm._s(_vm.caption)+" ")]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }